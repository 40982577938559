table {
    border: 1px solid #ddd;
}
tr:nth-child(odd){
    background-color: #efefef;
}

tr:nth-child(1){
    background-color: red;
    color: #fff;
}
.MuiDataGrid-columnHeaderWrapper {
    background: red;
    color: #fff;
    font-weight: bold;
}
.MuiDataGrid-root .MuiDataGrid-columnSeparator, .MuiDataGrid-root .MuiDataGrid-iconSeparator {
    display: none;
}

.MuiDataGrid-cell .MuiBox-root svg {
    margin: 10px;
    cursor: pointer;
}

.header-login {
    max-width: 135px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.wrap-import .color-green {
    background-color: rgb(1, 133, 1);
}

.wrap-import .color-green:hover {
    background-color: rgb(4, 112, 4);
}

.wrap-import .color-green .MuiButton-label {
    color: #fff;
}

.csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
.MuiDataGrid-cell > div {
    width: 100%;
}
.MuiDataGrid-cell div input {
    margin-top: 15px;
}

.error, .error-ten, .error-email, .error-tel, .error-address {
    color: red;
}
.alert, .MuiAlert-filledSuccess {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 50%;
    z-index: 1500;
}

.price-start{
    position: relative;
}

.price-start:after {
    content: '~';
    position: absolute;
    top: 50%;
    right: 2px;
}
.ck-content {
    max-height: 150px;
    min-height: 150px;
}
.upload-image {
    color: #fff;
    background-color: #3f51b5;
}

.order-customer {
    line-height: 1.2rem;
}